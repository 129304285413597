import api, { AxiosError } from 'axios'

import { store } from '@/utilities/store'
import { ErrorContract } from '@/types/api'
import { logout } from '@/utilities/functions'

const getBaseUrl = () => {
  const isNewAppVersion = store.getState().app.isNewAppVersion

  if (isNewAppVersion || (typeof isNewAppVersion === 'undefined' && window.ReactNativeWebViewAppVersion === '1.0.3')) {
    return process.env.VITE_APP_API_URL
  }

  return process.env.VITE_APP_ENV === 'production'
    ? 'https://orders.dserve.app/api'
    : 'https://orders-dev.dserve.app/api'
}

api.interceptors.request.use((config) => {
  config.baseURL = getBaseUrl()

  const token = store.getState().authentication.token
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error: AxiosError<ErrorContract>) => {
    if (error.response?.data.errorCode === 401) {
      logout()
    }

    return Promise.reject(error)
  },
)

export { api }
